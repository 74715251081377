/*General*/

h1, h2, h3, h4, h5, h6{
	font-family: "adelle",serif;
	font-style: normal;
}

p{
	font-family: "adelle-sans",sans-serif;
	font-style: normal;
	font-weight: 300;
	color: $cc-grey;
}

a{
	transition: all ease 0.25s;
	text-decoration: none !important;
}

a:hover{
	transition: all ease 0.25s;
}

strong{
	font-weight: 700;
}

img{
	width: 100%;
	height: auto;
}

button:focus{
	outline: none;
	border: none;
}

/*Columns*/

.cc-col{
	margin: 15px 0;
}

/*WOW*/

.wow {
  visibility: hidden;
}

/*Alert*/

.alert{
	margin: calc(6rem - 30px) 0;
	text-align: center;
	font-family: "adelle-sans",sans-serif;
	font-style: normal;
	font-weight: 300;
}

.alert-warning{
	border: none;
	background: $cc-brown-light;
	color: $cc-white;
	border-radius: inherit;
}

.alert-warning a{
	color: $cc-white;
	font-weight: 700;
}

.alert-warning a:hover{
	color: $cc-white;
}