/*Carousel*/

.cc-home-carousel-item{
	padding: 12rem 0;
	background-position: center;
	background-size: cover;
	color: $cc-white;
}

.cc-home-carousel-item h1{
	margin-bottom: 1rem;
}

.cc-home-carousel-item p{
	margin-bottom: 1.5rem;
	color: $cc-white;
}

.cc-home-carousel-item-content a{
	display: block;
	border: 2px solid $cc-white;
	text-transform: uppercase;
	color: $cc-white;
	text-align: center;
	padding: 1rem;
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 700;
}

.cc-home-carousel-item-content a:hover{
	background: $cc-white;
	color: $cc-brown;
}

.cc-home-carousel-item-image a{
	display: block;
	transition: all ease 0.25s;
}

.owl-theme .owl-dots .owl-dot span{
	margin: 5px;
}

.owl-theme .owl-nav.disabled+.owl-dots{
	margin: 0 auto 30px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
	background: $cc-brown-light;
}

/*Latest Coffins*/

.cc-wrapper-latest-coffins{
	padding: $cc-wrapper-padding-col;
	text-align: center;
}

.cc-wrapper-latest-coffins h2{
	margin: 0 0 1rem;
}

.cc-latest-coffins-header p{
	max-width: 420px;
	margin: 0 auto 1rem;
}

.cc-latest-coffin{
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
	transition: all ease 0.25s;
}

.cc-latest-coffin:hover{
	opacity: 0.85;
	transition: all ease 0.25s;
    -moz-transform: translate(0px, -5px);
    -ms-transform: translate(0px, -5px);
    -o-transform: translate(0px, -5px);
    -webkit-transform: translate(0px, -5px);
    transform: translate(0px, -5px);
}

.cc-latest-coffin a{
	display: block;
}

.cc-latest-coffin-image{
	padding: 2rem;
	background:$cc-white;	
}

.cc-latest-coffin-title{
	padding: 0.5rem;
	text-align: center;
	background: $cc-brown;
	color: $cc-white;
}

.cc-latest-coffin-title h4{
	font-size: 1rem;
	margin: 0;
}

/*Highlights*/

.cc-home-highlights-intro{
	margin: 0 auto calc(2rem - 17.5px);
}

.cc-wrapper-home-highlights{
	padding: 12rem 0;
	background-position: center center;
	background-size: cover;
	color: $cc-white;
	text-align: center;
}

.cc-wrapper-home-highlights .container{
	max-width: 850px;
}

.cc-wrapper-home-highlights h2{
	margin: 0 0 1rem;
}

.cc-wrapper-home-highlights h3{
	font-size: 1.25rem;
}

.cc-wrapper-home-highlights p{
	margin: 0 auto;
	color: $cc-white;
}

.cc-wrapper-home-highlights img{
	height: 60px;
	margin: 0 auto 1rem;
}

.cc-home-highlights-button{
	margin: calc(2rem - 17.5px) auto 0;
}

.cc-home-highlights-button a{
	display: block;
	padding: 1rem;
	margin: 0 auto;
	border: 2px solid $cc-white;
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 700;
	color: $cc-white;
	text-transform: uppercase;
}

.cc-home-highlights-button a:hover{
	background: $cc-white;
	color: $cc-brown;
}