/*Testimonials Archive*/

.cc-wrapper-archive-testimonials .container{
	max-width: 850px;
}

.cc-testimonial-single-content{
	margin: 0 0 1.5rem;
	padding: 0 0 1.5rem;
	border-bottom: 1px dashed $cc-border;
}

.cc-testimonial-single:last-child .cc-testimonial-single-content{
	margin: 0;
	padding: 0;
	border: none;
}

.cc-testimonial-single-content p{
	font-style: italic;
}

.cc-testimonial-single-content p:before, .cc-testimonial-single-content p:after{
	content: '"';
}

.cc-testimonial-single-content span{
	font-family: "adelle",serif;
	font-style: normal;
}

/*Testimonials Block*/

.cc-wrapper-testimonials{
	text-align: center;
}

.cc-testimonial-container{
	max-width: 850px;
	margin: 1rem auto 0;
}

.cc-testimonial-container-content p:before, .cc-testimonial-container-content p:after{
	content: '"';
}

.cc-testimonial-container-title span{
	font-family: "adelle",serif;
	font-style: normal;	
}