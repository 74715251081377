.cc-wrapper-cta{
	background: url('../images/cc-cta-bg.jpg');
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	font-family: "adelle",serif;
	font-style: normal;
	padding: 12em 0 0;
	color: $cc-white;
	text-align: center;
}

.cc-wrapper-cta .container{
	max-width: 850px;
}

.cc-wrapper-cta h2{
	margin-bottom: 1rem;
}

.cc-wrapper-cta p{
	color: $cc-white;
}

.cc-wrapper-cta p:last-child{
	margin: 6rem auto;
}

.cc-wrapper-cta a{
	display: block;
	padding: 1rem;
	margin: 1.5rem auto 0;
	border: 2px solid $cc-white;
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 700;
	color: $cc-white;
	text-transform: uppercase;
}

.cc-wrapper-cta a:hover{
	background: $cc-white;
	color: $cc-brown;
}