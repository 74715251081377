/*Wrappers*/

.cc-wrapper-about-us .container{
	max-width: 850px;
}

/*Content*/

.cc-about-us-content h2{
	margin: 0 0 1rem;
	line-height: 1.25;
}

/*Key Personnel*/

.cc-wrapper-team{
	padding: calc(6rem - 30px) 0;
}

.cc-wrapper-team .cc-col{
	margin: 30px 0;
}

.cc-team-member-image{
	margin: 0 0 1.5rem;
}

.cc-team-member-name h3{
	margin: 0;
}

.cc-team-member-job-title{
	margin: 0 0 0.5rem;
}

.cc-team-member-job-title span{
	font-family: "adelle",serif;
	font-style: normal;
	color: $cc-brown-light;
}

.cc-team-member-content p{
	margin: 0;
}

.cc-team-member-email{
	margin: 1rem 0 0;
}

.cc-team-member-email a{
	color: $cc-brown;
}

.cc-team-member-email a:hover{
	color: $cc-brown-light;
}