.cc-wrapper-faqs{
	padding: calc(6rem - 1rem) 0 6rem;
}

/*Accordion*/

.cc-faqs-accordion{
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 400;
	margin: 0;	
}

.cc-faq-card{
	background: none;
	border: none;
}

.cc-faq-card-header{
	background: none;
	padding: 0;
	border-bottom: 1px dashed $cc-border;
}

.cc-faq-card-header a{
	padding: 1rem 0;
	display: block;
	color: $cc-brown;
}

.cc-faq-card-header a:hover{
	color: $cc-brown-light;
}

.cc-faq-card-header a[aria-expanded="false"]:after{
    content: "\f067";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    float: right;
}

.cc-faq-card-header a[aria-expanded="true"]:after{
    content: "\f068";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    float: right;
}

.cc-faq-card-content{
	padding: 1rem 0;	
}

.cc-faq-card-content p{
	color: $cc-grey;
}

.cc-faq-card-content p a{
	color: $cc-brown-light;
	font-weight: 700;
}

.cc-faq-card-content p:last-child{
	margin-bottom: 0;
}