/*Header*/

.cc-header{
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 700;
	border-bottom: 1px solid #eee;
}

.cc-header-left{
	float: left;
	padding: 2.5em 0;
}

.cc-header-right{
	float: right;
}

/*Header Logo*/

.cc-logo{
	width: 275px;
}

.cc-logo a:hover{
	opacity: 0.85;
}

/*Header Nav*/

.cc-nav{
	float: right;
}

.cc-nav .nav li{
	margin: 0 1rem;
}

.cc-nav .nav li:first-child{
	margin-left: 0;
}

.cc-nav .nav li:last-child{
	margin-right: 0;
}

.cc-nav .nav li a{
	color: $cc-brown;
}

.cc-nav .nav li a:hover{
	color: $cc-brown-light;
}

.nav .active a{
	padding-bottom: 28px;
	border-bottom: 5px solid $cc-brown-light;
}

/*Header Contact*/

.cc-contact{
	text-align: right;
	margin: 0 0 25px;
	padding: 1.5em 0 0;
	font-weight: 400;
	color: #888888;
}

.cc-contact span{
	font-weight: 700;
	color: $cc-brown;
}

.cc-contact span a{
	color: $cc-brown;
}

.cc-contact span a:hover{
	color: $cc-brown-light;
}

.cc-contact-telephone:before{
	content: "Call: ";
	color: $cc-grey;
	font-weight: 400;
}

.cc-contact-email:before{
	content: "Email: ";
	color: $cc-grey;
	font-weight: 400;
}

.cc-contact-email{
	
}

/*Header Search*/

.search-form i{
	margin: 0 0 0 0.25rem;
}

.search-form .search-submit{
	background: $cc-brown;
	border-color: $cc-brown;
	cursor: pointer;
}

.search-form .search-submit:hover{
	background: $cc-brown-light;
	border-color: $cc-brown-light;
}

.cc-mobile-search-container{
	background: url('../images/cc-texture.png') #faf8f1;
    font-family: 'adelle', serif;
    font-style: normal;
    font-weight: 700;
}

.cc-mobile-search-container .input-group{
	margin: 15px;
	width: 100%;
}

/*Mobile Nav*/

.cc-mobile-menu-bar{
	background: $cc-brown;
	height: 45px;
	line-height: 45px;
	color: $white;
	font-family: 'adelle',serif;
    font-style: normal;
    font-weight: 700;
}

.cc-mobile-menu-bar-toggle{
	float: right;
	padding: 0 15px 0 0;
}

.cc-mobile-menu-bar-contact{
	float: left;
	font-size: 0;
}

.cc-mobile-menu-bar-contact a{
	padding: 0 15px;
	color: $white;
	border-right: 1px solid $white;
	display: inline-block;
	font-size: 1rem;
}

.cc-mobile-menu-bar-contact a:hover{
	background: $cc-brown-light;
}

#cc-nav-toggle{
	color: $cc-white;
}

#cc-nav-toggle:hover{
	opacity: 0.85;
}

#cc-nav-toggle i{
	margin-right: 5px;
	color: $cc-brown-light;
}

.cc-mobile-nav a{
	display: block;
	padding: 7.5px 15px;
	border-bottom: 1px solid $white;
	background: $cc-brown;
	color: $white;
	font-family: 'adelle',serif;
    font-style: normal;
    font-weight: 700;
}

.cc-mobile-nav a:hover{
	background: $cc-brown-light;
}

.cc-mobile-nav-toggle{
	text-align: right;
}

//Hamburgers

.hamburger-label{
	display: inline-block;
	margin: 0 0 0 2.5px;
}