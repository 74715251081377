/*Footer*/

.cc-footer{
	text-align: center;
	padding: 0 0 5rem;
	color: $cc-grey;
	font-family: "adelle",serif;
	font-style: normal;
	border-top: 1px solid $cc-border;
}

/*Footer Accreditations*/

.cc-footer-accreditations{
	margin-top: -2.5rem;
}

.cc-footer-accreditations img{
	width: auto;
	max-height: 76px;
}

/*Footer Logo*/

.cc-footer-logo{
	margin: 2rem auto 0;
	max-width: 267px;
}

/*Footer Contact*/

.cc-footer-contact {
	margin: 2rem 0 0;
}

.cc-footer-contact ul{
	margin: 0;
	padding: 0;
	list-style: none;
}

.cc-footer-contact ul li a{
	color: $cc-brown;
}

.cc-footer-contact ul li a:hover{
	color: $cc-brown-light;
}

.cc-footer-contact ul li strong{
	color: $cc-brown;
}

/*Footer Social*/

.cc-footer-social{
	margin: 2rem 0 0;
}

.cc-footer-social a{
	display: block;
}

.cc-footer-social a:hover{
	opacity: 0.85;
}

.cc-footer-social img{
	width: 32px;
}

/*Footer Copyright*/

.cc-footer-copyright{
	margin: 2rem 0 0;
}

.cc-footer-copyright p{
	margin: 0;
}

.cc-footer-copyright p a{
	font-weight: 700;
	color: $cc-brown;
}

.cc-footer-copyright p a:hover{
	font-weight: 700;
	color: $cc-brown-light;
}