/*Wrapper*/

.cc-wrapper-contact{
	padding: 2rem 0 calc(6rem - 30px);
}

.cc-wrapper-contact .container{
	max-width: 1000px;
}

.cc-wrapper-contact .cc-single-coffin-code{
	margin-bottom: calc(2rem - 5px);
}

/*Contact Form*/

.cc-contact-form{
	padding-right: 30px;
}

.cc-contact-form p{
	margin: 0 0 1.07rem;
	color: inherit;
}

.cc-contact-form input, .cc-contact-form label, .cc-contact-form textarea{
	width: 100%;
}

.cc-contact-form label{
	margin: 0;
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 700;
}

.cc-contact-form input, .cc-contact-form textarea{
	padding: 0.4rem;
	font-family: "adelle-sans",sans-serif;
	border: 1px solid $cc-border;
	color: $cc-grey;
}

.cc-contact-form input[type=submit]{
	padding: 1rem;
	margin: 22px 0 0;
	background: none;
	border: 2px solid $cc-brown;
	color: $cc-brown;
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
	transition: all ease 0.25s;
	cursor: pointer;
}

.cc-contact-form input[type=submit]:hover{
	background: $cc-brown;
	color: $white;
	transition: all ease 0.25s;
}

/*Sidebar*/

.cc-contact-sidebar ul{
	padding: 0;
	list-style: none;
	margin-bottom: 1.6rem;
}

.cc-contact-sidebar div{
	border-top: 1px dashed $cc-border;
	padding-top: 1.5rem;
}

.cc-contact-sidebar div:first-child{
	border: none;
	padding-top: 0;
}

.cc-contact-sidebar a{
	color: $cc-brown;
}

.cc-contact-sidebar a:hover{
	color: $cc-brown-light;
}

.cc-contact-sidebar-alternative p:last-child{
	margin-bottom: 0;
}