.cc-wrapper-our-coffins{
	padding: 0;
}

.cc-our-coffins-highlight-image{
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #cccccc;
}

.cc-our-coffins-highlight-content-col{
	display: flex;
	align-items: center;
	justify-content: center;
}

.cc-our-coffins-highlight-content-container{
	padding: 5rem;
}

.cc-our-coffins-highlight-content h2{
	margin: 0 0 1rem;
}

.cc-our-coffins-highlight-content p:last-child{
	margin: 0;
}