@media (max-width: 1199px) { 
	.container{
		max-width: 100%;
	}
}

@media (max-width: 1050px) {
	.cc-nav .nav li{
		margin: 0 0.5rem;
	}

	.cc-contact-telephone:before{
		content: "T: ";
		color: $cc-grey;
		font-weight: 400;
	}
	
	.cc-contact-email{
		margin: 0 15px 0 0;
	}
	
	.cc-contact-email:before{
		content: "E: ";
		color: $cc-grey;
		font-weight: 400;
	}	
}

@media (max-width: 991px) {
	.cc-home-carousel-item{
		padding: 12rem 2rem;
		text-align: center;
	}
	
	.owl-carousel .owl-item img{
		max-width: 75%;
		margin: 1.75rem auto 0;
	}
	
	.order-1{
		order: 2;
	}
	
	.order-2{
		order: 1;
	}
	
	.cc-single-coffin-sidebar{
		margin: 2rem 0 0;
	}
	
	.cc-contact-form{
		padding: 0;
	}
	
	.cc-our-coffins-highlight-content-container, .cc-page-image-content-container{
		padding: 4rem;
	}
	
	.cc-single-coffin-sidebar img{
		display: block;
		margin: 0 auto;
	}
}

@media (min-width: 980px) {
	.cc-mobile-nav-toggle, .cc-mobile-nav-container{
		display: none;
	}
	
	.cc-mobile-menu-bar{
		display: none;
	}
	
	.cc-mobile-search-container{
		display: none !important;
	}
}

@media (max-width: 979px) {
	.cc-header{
		padding: 2rem 0;
	}
	
	.cc-header-left{
		padding: 0;
		float: none;
	}
	
	.cc-logo{
		margin: 0px auto;
	}
	
	.cc-header-right{
		display: none;
	}
	
	.cc-nav{
		display: none;
	}
	
	.cc-contact{
		padding: 0;
		margin: 0 0 9px;
	}
	
	.cc-contact-desktop{
		display: none;
	}
}

@media (min-width: 769px){
	.cc-single-coffin-gallery-image-standing-mobile, .cc-single-coffin-gallery-image-side-mobile, .cc-gallery-message-mobile{
		display: none;
	}
}

@media (max-width: 768px){
	.cc-home-carousel-item{
		padding: 6rem 2rem;
	}
	
	.cc-single-coffin-gallery-image-standing-desktop, .cc-single-coffin-gallery-image-side-desktop, .cc-gallery-message-desktop{
		display: none;
	}
	
	/*.magnify>.magnify-lens{
		display: none !important;
	}*/
}

@media (max-width: 767px) {
	.cc-wrapper-coffins{
		padding-top: 15px;
	}	
	
	.cc-coffins-categories{
		margin: 0;
	}
}

@media (max-width: 480px) {
	html, body{
		font-size: 14px;
	}
	
	.cc-home-carousel-item{
		padding: 6rem 0;
	}	
	
	.cc-col{
		margin: 7.5px 0;
	}
	
	.cc-page-header{
		padding: 4rem 0;
	}
	
	.cc-wrapper{
		padding: $cc-wrapper-padding-mob;
	}
	
	.cc-wrapper-our-coffins{
		padding: 0;
	}
	
	.cc-wrapper-home-highlights{
		padding: 4rem 0 calc(4rem + 35px);
	}

	.cc-wrapper-cta{
		padding: 4rem 0;
	}
	
	.cc-wrapper-cta p:last-child{
		margin: 2rem 0 1rem;
	}
	
	.cc-wrapper-coffins{
		padding-top: 15px;
	}
	
	.cc-coffins-categories{
		margin: 0 0 7.5px;
	}
	
	.cc-coffins-col{
		margin: 7.5px 0;
		padding: 0 7.5px;
	}
	
	.cc-coffins-col-title{
		font-size: 12px;
	}
	
	.cc-coffins-row-mob{
		padding: 0 7.5px;
	}
	
	.cc-wrapper-single-coffin, .cc-wrapper-contact{
		padding-top: 15px;
	}
	
	.cc-wrapper-contact .cc-single-coffin-code{
		margin: 0 0 10px;
	}
	
	.cc-single-coffin-code{
		margin: 0 0 15px;
	}
	
	.cc-single-coffin-gallery .tab-content{
		padding: 2rem 15px;
	}
	
	.cc-single-coffin-gallery .tab-content{
		height: 480px;
	}
	
	.cc-single-coffin-gallery-image-standing{
		height: 420px;
	}
	
	.cc-single-coffin-sidebar img{
		width: 50%;
	}
	
	.cc-gallery-message{
		padding: 0 3rem 2rem;
	}
	
	.cc-single-coffin-related-coffin:hover, .cc-coffins-col-coffin:hover, .cc-latest-coffin:hover{
		transform: none;
	}
	
	.cc-our-coffins-highlight-content-container, .cc-page-image-content-container{
		padding: 3rem 15px;		
	}
	
	.cc-wrapper-team .cc-col{
		margin: 15px 0;
	}

}

@media (max-width: 320px) {
	.cc-coffins-col-title{
		font-size: 10px;
	}
	
	.cc-single-coffin-gallery .nav-tabs .nav-link{
		font-size: 13px;
	}	
}