/*Coffins Archive*/

.cc-coffins-categories{
	border-top: 1px dashed $cc-border;
	border-bottom: 1px dashed $cc-border;
	margin: 0 0 15px;
	padding: 15px 0;
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 700;
	text-align: center;
}

.cc-coffins-categories a{
	color: $cc-brown;
}

.cc-coffins-categories a:hover{
	color: $cc-brown-light;
}

.cc-coffins-categories ul{
	list-style: none;
	margin: 0;
	padding: 0;
}

.cc-coffins-categories ul li{
	display: inline-block;
	margin: 0 1em;
}

.cc-coffins-categories ul li:first-child{
	margin-left: 0;
}

.cc-coffins-categories .current-cat a, .post-type-archive-coffins .cat-item-all a{
	color: $cc-brown-light;
}

/* styles for desktop */
.tinynav{
	display: none;
}

/* styles for mobile */
@media screen and (max-width: 767px) {
    .tinynav {
	    display: block;
	    width: 100%;
	}
	
    #cc-coffins-categories-nav{
	    display: none;
	}
}

.cc-coffins-col{
	text-align: center;
	margin: 15px 0;
}

.cc-coffins-col-coffin{
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.20);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.20);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.20);
	transition: all 0.25s ease;	
}

.cc-coffins-col-coffin:hover{
	opacity: 0.85;
	transition: all 0.25s ease;
    -moz-transform: translate(0px, -5px);
    -ms-transform: translate(0px, -5px);
    -o-transform: translate(0px, -5px);
    -webkit-transform: translate(0px, -5px);
    transform: translate(0px, -5px);
}

.cc-coffins-col-coffin a{
	display: block;
}

.cc-coffins-col-image{
	padding: 1.25em;
	background: $cc-white;
}

.cc-coffins-col-image img{
	width: 100%;
	height: auto;
	transition: all 0.25s ease;
	-webkit-backface-visibility: hidden;
}

.cc-coffins-col-title{
	font-family: "adelle",serif;
	font-style: normal;
	background: $cc-brown;
	color: $cc-white;
	padding: 0.5em;
}

/*Coffins Single*/

.cc-wrapper-single-coffin{
	padding: 4rem 0;
	
	.product--title{
		h1{
			font-size: 2rem !important;
			margin: 0 0 1rem !important;	
		}
	}
}

.cc-single-coffin-content{
	margin-bottom: 3em;	
}

.cc-single-coffin-gallery{
	background: $cc-white;
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.cc-single-coffin-gallery .tab-content{
	height: 907px;
    padding: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cc-single-coffin-gallery .cc-personal-tab-content{
	height: 965px;
}

.cc-single-coffin-gallery .cc-club-tab-content{
	height: 944px;
}

.cc-single-coffin-gallery-image-standing{
	width: auto;
	height: 700px;
}

.cc-single-coffin-gallery-image-side{
	width: 100%;
	height: auto;
}

.cc-single-coffin-gallery .nav-tabs{
	border: none;
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 400;
	text-align: center;
	border-bottom: 1px solid #ddd;
}

.cc-single-coffin-gallery .nav-tabs .nav-item{
	width: 50%;
	display: inline-block;
	margin-bottom: 0;
}

.cc-single-coffin-gallery .nav-tabs .nav-item:first-child{
	border-right: 1px solid #ddd;
}

.cc-single-coffin-gallery .nav-tabs .nav-link{
	border: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	padding: 1rem;
	opacity: 0.35;
	color: $cc-brown;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
}

.cc-single-coffin-gallery .nav-tabs .nav-link.active{
	opacity: 1;
}

.cc-gallery-tab span{
	margin: 0 0 5px;
	display: block;
}

.cc-gallery-tab-thumb{
	width: 150px;
	height: 100px;
	margin: 0 auto 5px;
	border: 1px solid $cc-border;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cc-gallery-thumb-side{
	max-width: 100px;
	height: auto;
}

.cc-gallery-thumb-standing{
	max-width: 30px;
	height: auto;
}

/*Magnify*/

.magnify > .magnify-lens {
  width: 175px;
  height: 175px;
}

/*Galley Message*/

.cc-gallery-message{
	text-align: center;
	font-size: 12px;
	padding: 2em;
	color: #bbbbbb;
}

.cc-gallery-message i{
	margin: 0 5px 0 0;
}

/*Sidebar*/

.cc-single-coffin-sidebar{
	font-family: "adelle",serif;
	font-style: normal;
}

.cc-single-coffin-sidebar h3{
	font-size: 1.25rem;
	margin: 2rem 0 1rem;
}

.cc-single-coffin-sidebar h3:first-child{
	margin-top: 0;
}

.cc-single-coffin-sidebar p{
	text-align: left;
	font-size: 14px;
	color: $cc-grey;
}

.cc-single-coffin-sidebar p:last-child{
	margin-bottom: 0;
}

.cc-single-coffin-sidebar p a{
	color: $cc-brown;
	font-weight: 700;
}

.cc-single-coffin-sidebar p a:hover{
	color: $cc-brown-light;
}

.cc-single-coffin-sidebar img{
	width: 75%;
	max-width: 300px;
	height: auto;
}

.cc-single-coffin-sidebar-section{
	border-top: 1px dashed #ccc;
	padding: 1.5em 0 0;
	margin: 0 0 1.5em;	
}

.cc-single-coffin-sidebar-section:last-child{
	margin-bottom: 0;
}

.cc-single-coffin-sidebar-section ul{
	list-style: none;
	padding: 0;
}

.cc-single-coffin-sidebar-section ul a{
	color: $cc-brown;
}

.cc-single-coffin-sidebar-section ul a:hover{
	color: $cc-brown-light;
}

.cc-single-coffin-sidebar-section-pricing strong{
	color: $cc-black;
}

.cc-single-coffin-sidebar-section-pricing p:last-child{
	font-size: 10px;
}

/*Coffin Code*/

.cc-single-coffin-code{
	background: $cc-brown-light;
	color: $cc-white;
	margin: 0 0 2rem;
	padding: 1rem;
	text-align: center;
}

.cc-single-coffin-code p{
	margin: 0;
	color: $cc-white;
}

/*Share Coffin*/

.cc-single-coffin-share{
	text-align: center;
	margin-bottom: 2.75em;
}

.cc-single-coffin-share h4{
	font-family: "adelle",serif;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 1em;
}

/*Other Coffins You May Also Like*/

.cc-single-coffin-related-wrapper{
	font-family: "adelle",serif;
	font-style: normal;
	/*margin-bottom: 2.75em;*/
}

.cc-single-coffin-related-container{
	border-top: 1px dashed $cc-border;
	padding: 2.25em 0 0;
	margin: 0;
}

.cc-single-coffin-related-container h2{
	font-size: 1.5rem;
	margin: 0 0 calc(1.75em - 15px);
	text-align: center;
}

.cc-single-coffin-related-coffin{
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
	transition: all ease 0.25s;
}

.cc-single-coffin-related-coffin:hover{
	opacity: 0.85;
	transition: all ease 0.25s;
    -moz-transform: translate(0px, -5px);
    -ms-transform: translate(0px, -5px);
    -o-transform: translate(0px, -5px);
    -webkit-transform: translate(0px, -5px);
    transform: translate(0px, -5px);
}

.cc-single-coffin-related-coffin a{
	display: block;
}

.cc-single-coffin-related-coffin-image{
	padding: 2em;
	background:$cc-white;	
}

.cc-single-coffin-related-coffin-title{
	padding: 0.5em;
	text-align: center;
	background: $cc-brown;
	color: $cc-white;
	transition: all ease 0.25s;	
}

// Enquiry Form

.popup-content{
	position: relative;
	
	.closeBTN{
		display: block;
		float: none;
		line-height: 1;
		right: 10px;
		position: absolute;
		top: 10px;
	}
}

.cc-coffin-enquiry-form{
	label{
		color: $cc-black;
		display: block;
		margin: 0;
		font-family: "adelle",serif;
		font-style: normal;
		font-weight: 700;
	}
	
	.wpcf7-form-control-wrap{
		display: block;
		margin: 0 0 0.5rem;
		
		&.cc-message{
			margin: 0 0 20px;
		}
		
		input[type=text], input[type=email], textarea{
			width: 100%;
		}
	}
	
	p{
		font-size: 0.75rem;
		font-family: "adelle-sans",sans-serif;
		font-style: normal;
		font-weight: 300;
		margin: 0 0 20px;
		color: $cc-grey;
	}

	input[type=submit]{
		background-color: #2e9318;
		border: none;
		border-radius: 0.25rem;
		color: $cc-white;
		cursor: pointer;
		font-family: "adelle",serif;
		font-style: normal;
		font-weight: 700;
		padding: 0.625rem 1rem 0.5rem 1rem;
		text-transform: uppercase;
	}
	
	div.wpcf7-response-output{
		margin: 20px 0 0;
	}
}

/*Go Back*/

.cc-single-coffin-back{
	font-family: "adelle",serif;
	font-style: normal;	
	border-top: 1px dashed $cc-border;
	padding: 2em 0 0;
	margin: 0;
	text-align: center;
}

.cc-single-coffin-back i{
	margin-right: 0.25em;
}