// Grid settings
$main-sm-columns:       		12;
$sidebar-sm-columns:    		4;

// Colours

$cc-brown:						#63563b;
$cc-brown-hover:				#7a6f58;
$cc-brown-light:				#b2a17b;
$cc-grey:						#666666;
$cc-border:						#cccccc;
$cc-white:						#ffffff;
$cc-black:						#212529;

// Padding
$cc-wrapper-padding:			6rem 0;
$cc-wrapper-padding-mob:		3rem 0;
$cc-wrapper-padding-col:		calc(6rem - 15px) 0;
$cc-wrapper-coffins-padding:	30px 0 calc(6rem - 15px);

//Hamburgers
$hamburger-layer-color:			$cc-brown-light;
$hamburger-padding-x:			0;
$hamburger-padding-y:			0;
$hamburger-layer-width:			14px;
$hamburger-layer-height:		2px;
$hamburger-layer-spacing:		2px;
$hamburger-layer-border-radius:	0;