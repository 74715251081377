.cc-page-header{
	padding: 4rem 0;
	background: url('../images/cc-texture.png') $cc-brown;
	background-size: 200px;
	color: $cc-white;
	text-align: center;
}

.cc-page-header h1{
	margin: 0 0 1rem;
}

.cc-page-header p{
	max-width: 530px;
	margin: 0 auto;
	color: $cc-white;
}

/*Wrappers*/

.cc-wrapper{
	background: url('../images/cc-texture.png') #faf8f1;
	background-size: 200px;
	padding: $cc-wrapper-padding;
}

.cc-wrapper-coffins{
	padding: $cc-wrapper-coffins-padding;
}

.cc-wrapper-search{
	padding: 30px 0;
}

/*Page Image*/

.cc-page-image{
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.cc-page-image-content-col{
	display: flex;
	align-items: center;
	justify-content: center;
	background: url('../images/cc-texture.png') $cc-brown-light;
}

.cc-page-image-content-container{
	padding: 5rem;
}

.cc-page-image-content h2{
	margin: 0 0 1rem;
	color: $cc-white;
}

.cc-page-image-content p{
	color: $white;
}

.cc-page-image-content p:last-child{
	margin: 0;
}